<template lang="pug">
div
  v-btn(
    xSmall
    download
    target="_blank"
    :href="value"
  ) Скачать
  v-btn.ml-2(
    xSmall
    target="_blank"
    href="https://www.gosuslugi.ru/pgu/eds"
  ) Проверить
  div.mt-1(style="font-size: 10px;line-height: 15px;")
    | Для проверки - на открывшейся странице выберите пункт "отсоединенная,
    | в формате PKCS#7", далее загрузите файл результата и подпись файла.
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
};
</script>
