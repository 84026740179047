<template lang="pug">
  h3(:style="style") {{text}}
</template>

<script>
import {
  InspectionResolutions,
  InspectionFailReasons,
} from '@/utils/constants.js';

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    result: Boolean,
    failReasons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    text() {
      const resolution = InspectionResolutions[this.type][this.result];
      return !this.failReasons
        ? resolution
        : resolution + ' - ' + InspectionFailReasons[this.failReasons[0]].name;
    },
    style() {
      return (
        'color: ' +
        (!this.result
          ? InspectionFailReasons[this.failReasons[0]].color
          : '#2ecc71')
      );
    },
  },
};
</script>
