<template>
  <v-simple-table dense class="definition-list">
    <template #default>
      <tbody>
        <tr
          v-for="{ label, value: val, component, props } in value"
          :key="label"
        >
          <template v-if="component">
            <td class="grey--text" style="width: 40%;">
              {{ label }}
            </td>
            <td>
              <component :is="component" v-bind="{ ...props, value: val }" />
            </td>
          </template>
          <template v-else-if="val">
            <td class="grey--text" style="width: 40%;">
              {{ label }}
            </td>
            <td>{{ val }}</td>
          </template>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object],
      required: true,
    },
  },
};
</script>

<style lang="scss">
.definition-list table {
  table-layout: fixed;
}
</style>
