<template lang="pug">
PageLayout(
  :title="'Карточка осмотра' + (id  ? ' №' + id : '')"
  backTooltip="Вернуться к списку осмотров"
  backRoute="inspections:list"
  :loading="!id"
  fluid)
  v-col(cols="12" sm="4")
    v-card
      v-img(:src="workerPhoto && workerPhoto.url")
        div.archived(
          v-if="workerPhoto && workerPhoto.state === 'ARCHIVED'"
        ) Фото архивировано {{workerPhoto.archived}}
      v-card-title
        span.mr-3 Личные данные
        v-spacer
        v-menu(offset-y)
          template(v-slot:activator="{ on: menu, attrs }")
            v-btn(
              color="primary"
              depressed
              v-on="{ ...menu }"
            ) Действия
          v-list
            v-list-item(
              :to="{ name: 'employees:card', params: { id: workerId } }"
              v-if="$can('READ_EMPLOYEES_DETAILED')"
            )
              v-list-item-title Карточка работника
            v-list-item(
              v-if="$can('READ_INSPECTIONS_ALL')"
              :to="{ name: 'inspections:list', query: { workerId } }"
            )
              v-list-item-title Все осмотры работника
            v-list-item(
              v-if="workerPhoto && workerPhoto.state !== 'NONE'"
              :disabled="workerPhoto && workerPhoto.state === 'ARCHIVED' || !$can('ARCHIVE_EMPLOYEE_PHOTO')"
              @click="archivePhotoDialog = true"
            )
              v-list-item-title Архивировать фото
      DefinitionList(:value="workerData")

  v-col(cols="12" sm="4")
    v-card(style="overflow: hidden;")
      div.text-center
        video(
          v-if="videoUrl"
          controls
          style="width: 100%"
          preload
        )
          source(:src="videoUrl" type="video/mp4")

  v-col(cols="12" sm="4")
    v-card
      v-card-title
        span.mr-3 Медицинские показатели
        v-spacer
        v-menu(offset-y left)
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              color="primary"
              depressed
              v-on="on"
            ) Действия
          v-list.pa-0
            v-list-item(
              v-if="$can('DOWNLOAD_MEDRESULT')"
              :download="data.pdfName"
              target="_blank"
              :href="data.pdfUrl"
            )
              v-list-item-title  Файл результата
            v-list-item(
              v-if="$can('PRINT_STICKERS')"
              @click="printSticker(item.id, item.organization.id, data.hostname)"
            )
              v-list-item-title  Повторная печать стикера
      DefinitionList(:value="inspectionData")
      v-list(v-if="currentDocuments.length > 0")
        span.px-4.text-caption Справки
        v-list-item(
          v-for="document in currentDocuments"
          :key="document.url"
          :href="document.url"
          target="_blank"
          download
        )
          v-list-item-title.text-wrap(
          ) {{ document.noteId != null ? "Справка с направлением на консультацию" : "Выписка из меджурнала"}}
          v-list-item-action
            v-icon mdi-download

  v-dialog(v-model="archivePhotoDialog" max-width="450")
    v-card
      v-card-title
        h4.text-center.font-weight-light(
          style="word-break: normal;"
        ) Отправить фото в архив? При следующей авторизации на терминале работнику необходимо будет сделать новое фото.
      v-card-actions.d-flex.justify-center
        v-btn.mr-3(
          @click="archivePhotoDialog = false"
          text
        ) Отмена
        v-btn(
          @click="dispatchArchiveRequest"
          :loading="$root.isLoadingGlobal"
          color="primary"
          :disabled="$root.isLoadingGlobal"
          text
        ) OK

</template>

<script>
import PageLayout from '@/components/layouts/PageLayout';
import DefinitionList from '@/components/DefinitionList';
import FileHash from './view/FileHash';
import ResultBadge from './view/ResultBadge';

import eventBus from '@/plugins/eventBus';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    PageLayout,
    DefinitionList,
  },
  data() {
    return {
      archivePhotoDialog: false,
    };
  },
  computed: {
    ...mapGetters('INSPECTION', [
      'id',
      'item',
      'workerId',
      'workerPhoto',
      'worker',
      'videoUrl',
      'medical',
      'data',
      'currentDocuments',
    ]),
    workerData() {
      if (!this.worker) return [];

      return [
        { label: 'ФИО', value: this.worker.name },
        { label: 'Дата рождения', value: this.worker.dateOfBirth },
        { label: 'Возраст', value: this.worker.age },
        { label: 'Пол', value: this.worker.gender },
        { label: 'Табельный №', value: this.worker.personellNumber },
        { label: 'Телефон', value: this.worker.phone },
        { label: 'ВУ', value: this.worker.license },
        { label: 'Организация', value: this.worker.organization },
        { label: 'Статус', value: this.worker.status },
        { label: 'Подпись', value: this.worker.hash },
      ];
    },
    inspectionData() {
      if (!this.medical) return [];
      const data = [
        // medical
        {
          label: 'Артериальное давление, мм рт. ст.',
          value: this.medical.pressure,
        },
        { label: 'Пульс, уд./мин', value: this.medical.pulse },
        { label: 'Алкоголь, мг/л', value: this.medical.alcohol },
        { label: 'Жалобы', value: this.medical.complaints },
        { label: 'Сон', value: this.medical.sleep },
        { label: 'Температура, °C', value: this.medical.temperature },
        { label: 'Тип тестирования', value: this.medical.reactionType },
        { label: 'Результат тестирования', value: this.medical.reactionResult },
        // inspection
        { label: 'Тип осмотра', value: this.data.type },
        { label: 'Медработник', value: this.data.medworker },
        { label: 'Подпись медработника', value: this.data.hash },
        {
          label: 'Подпись файла медработника',
          value: this.data.eds,
          component: this.data.eds !== '-' ? FileHash : null,
        },
        { label: 'Дата осмотра', value: this.data.dateTime },
        { label: 'Продолжительность', value: this.data.duration },
        { label: 'Точка осмотра', value: this.data.hostname },
        {
          label: 'Результат осмотра',
          component: ResultBadge,
          props: {
            result: this.item.result,
            type: this.item.type,
            failReasons: this.item.failReasons,
          },
        },
        { label: 'Комментарий', value: this.data.comment },
        { label: 'Заключение', value: this.data.failConclusion },
      ];

      return data;
    },
  },
  methods: {
    ...mapActions('INSPECTION', ['archivePhoto']),

    printSticker(inspectionId, organizationId, hostnameId) {
      eventBus.$emit('showenPrintStickerModal', {
        inspectionId,
        organizationId,
        hostnameId,
      });
    },

    // TODO: move all 'archive photo logic' to separate component
    // remove copypaste from 'employees/view' and this file
    // P.S. in order to do it easyer just blame function below,
    // see which commit it was
    async dispatchArchiveRequest() {
      try {
        await this.archivePhoto();
        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Фото работника успешно архивировано',
          text: '',
        });
      } catch (err) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Произошла ошибка архивации фото работника',
          text: '',
        });
      } finally {
        this.archivePhotoDialog = false;
      }
    },
  },
};
</script>

<style lang="scss">
.archived {
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  position: absolute;
  height: 10%;
  max-height: 50px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  bottom: 0;
  display: flex;
  font-size: #{'clamp(#{1rem}, #{-0.875rem} + #{3vw}, #{1.7rem})'};
  align-items: center;
  justify-content: center;
}
</style>
