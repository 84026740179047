<template lang="pug">
v-container(:fluid="fluid")
  v-tooltip(top)
    template(v-slot:activator="{ on }")
      v-btn.mb-2(
        v-on="on"
        icon
        :to="{ name: backRoute }"
      )
        v-icon(small) fa-long-arrow-alt-left
    span {{backTooltip}}
  span.text-h5.font-weight-light {{title}}
  Spinner(v-if="loading")
  v-row(v-else)
    slot
</template>

<script>
import Spinner from '@/components/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    fluid: Boolean,
    loading: Boolean,
    backTooltip: {
      type: String,
      default: '',
    },
    backRoute: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
